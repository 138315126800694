import React, { useContext, useEffect } from 'react';
import { graphql } from 'gatsby';

import { GeneralHero } from '../components/General/GeneralHero';
import { ThankYouBody } from '../components/ThankYou/ThankYouBody';
import { SEO } from '../components/SEO';
import { SectionBlue } from '../components/Sections/SectionBlue';
import { HeroWaveTwo } from '../components/Waves/HeroWaveTwo';
import Form from '../components/ContactForm/Form';

import LocaleContext from '../context/LocaleContext';

export default function ThankYou({ data: { thankyou }, location }) {
  const { metaTitle, metaDescription, hero, contactForm, pardotUrl } = thankyou;
  const { handleRedirect, ipData } = useContext(LocaleContext);

  const pageContent =
    thankyou.featureHeader || thankyou.featuredResources.length;

  useEffect(() => {
    ipData && handleRedirect(location.pathname, true);
  }, [ipData]);

  return (
    <>
      <SEO title={metaTitle} description={metaDescription} />
      <SectionBlue top extraBottom>
        <GeneralHero heroData={hero} />
        <HeroWaveTwo />
      </SectionBlue>
      {!!pageContent ? <ThankYouBody thankyou={thankyou} /> : null}
      <Form
        pardotUrl={pardotUrl}
        contactForm={contactForm}
        location={location}
        noForm
      />
    </>
  );
}

export const query = graphql`
  query ($slug: String!) {
    thankyou: sanityThankYou(slug: { current: { eq: $slug } }) {
      _id
      title
      metaTitle
      metaDescription
      slug {
        current
      }
      hero {
        ctaText
        ctaLink
        _rawContent
        backgroundImage {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      featureHeader
      _rawFeatureBody
      videoVariant
      featureImage {
        asset {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      # svgVariant
      thumbnailVideoUrl
      fileDownload {
        asset {
          url
        }
      }
      downloadCTAText
      shareText
      socialAssets {
        faIcon
        socialLink
        title
      }
      contactForm {
        header
        privacyPolicy
      }
      pardotUrl
      featuredResourcesHeader
      featuredResources {
        title
        blurb
        ctaText
        ctaLink
        image {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      _rawCustomerLogosHeader
      customerLogos {
        logo {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      circleImageLeft {
        asset {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      circleImageRight {
        asset {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  }
`;
