import React from 'react';

import { Container } from '@material-ui/core';

import { Resources } from './Resources';
import { CustomerLogos } from '../CustomerLogos';
import { SectionWhite } from '../Sections/SectionWhite';

export const ThankYouBody = ({ thankyou }) => {
  const { featuredResources, _rawCustomerLogosHeader, customerLogos } =
    thankyou;

  return (
    <>
      <Container>
        {!!featuredResources.length ? <Resources thankyou={thankyou} /> : null}
      </Container>
      {customerLogos && (
        <SectionWhite top zIndex={3} bottom>
          <CustomerLogos
            customerLogosArray={customerLogos}
            customerLogosHeader={_rawCustomerLogosHeader}
          />
        </SectionWhite>
      )}
    </>
  );
};
