import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import {
  Grid,
  Typography,
  makeStyles,
  Card,
  CardHeader,
  CardContent,
  useMediaQuery,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.workwaveBlue,
    boxShadow: '0 10px 40px 0px rgba(0,0,0,0.15)',
    borderRadius: '12px',
    padding: 0,
    margin: `1rem`,
  },
  arrow: {
    marginLeft: '.25rem',
    transition: 'transform .25s ease-in-out',
    display: 'inline-block',
  },
  resourceTitle: {
    fontSize: '1.75rem',
  },
  ctaLink: {
    marginBottom: '.5rem',
    '&:hover': {
      '& $arrow': { transform: 'translateX(10px)' },
    },
  },
}));

export const Resource = ({ resource, _id }) => {
  const classes = useStyles();
  const md = useMediaQuery('(max-width: 960px)');
  const sm = useMediaQuery('(max-width: 715px)');

  const fs =
    _id === 'c09e8970-8f4b-426a-a09d-0590d03b0beb' ||
    _id === '7eac20bb-4388-4ff7-b0ec-a23dedb4fa6d';
  const rm = _id === 'ea486ac8-9615-4569-af36-4904af29fac9';

  return (
    <Grid item xs={12} sm={10} md={5} lg={4} style={{ zIndex: 100 }}>
      <Card elevation={0} className={classes.root}>
        <CardHeader
          style={{
            padding: 0,
          }}
          subheader={
            <GatsbyImage
              image={resource.image?.asset?.gatsbyImageData}
              style={{ height: '14.125rem', backgroundSize: 'cover' }}
            />
          }
        ></CardHeader>
        <CardContent
          style={{
            height: md ? 'auto' : rm ? '16rem' : fs ? '21rem' : '19rem',
            padding: '18px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Grid
            style={{ height: sm ? null : '100%', margin: '0' }}
            container
            direction='column'
            justifyContent='flex-start'
            alignItems='flex-start'
          >
            <Typography
              variant='h4'
              className={classes.resourceTitle}
              style={{
                lineHeight: '1.2',
                marginBottom: '10px',
                textTransform: 'uppercase',
              }}
            >
              {resource.title}
            </Typography>

            <Typography variant='body1' style={{ lineHeight: 1.4 }}>
              {resource.blurb}
            </Typography>
          </Grid>
          <Grid container>
            <a
              href={resource.ctaLink}
              target='_blank'
              rel='noreferrer'
              style={{
                textDecoration: 'none',
              }}
            >
              <Typography
                variant='body2'
                className={classes.ctaLink}
                style={{
                  color: '#002D5C',
                  fontWeight: 600,
                  fontSize: '1.25rem',
                  marginTop: md && '1rem',
                }}
              >
                {resource.ctaText}
                <span className={classes.arrow}>→</span>
              </Typography>
            </a>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};
